const SectionAppsInstitutional = () => {
  return (
    <section className="section-apps-institutional">
      <div className="section-apps-institutional__text">
        <h1 className="section-apps-institutional__title">
          A Onze na palma da sua mão
        </h1>
        <p className="section-apps-institutional__description">
            Se você não baixou o aplicativo da Onze ainda, está perdendo tempo.
        </p>
        <div className="section-apps-institutional__download download">
            <p className="download__description">
            Baixe agora nosso aplicativo
            </p>
            <div className="download__images-wrapper">
                <img src="/images/get-apple.png" alt="apple" className="download__image"/>
                <img src="/images/get-google.png" alt="google-play" className="download__image"/>
            </div>
        </div>
      </div>
    </section>
  )
}

export default SectionAppsInstitutional;
