import Button from 'components/Button'
import Icon from 'rv-components-onze/src/Icon'
import enhancer from './hooks'
import Condition from 'components/Condition'

const SectionHero = ({ onClickSidebar, twoButtons = false }) => {
  return (
    <section className="b2b-hero">
      <div className="container b2b-hero__content">
        <div className="b2b-hero__info">
          <h2 className="b2b-hero__title">
            O cenário mudou,
            <div>a previdência também.</div>
          </h2>
          <div className="b2b-hero__ilustration__path"></div>
          <h4 className="b2b-hero__sub-title">
            Deixe a Onze cuidar do futuro financeiro dos seus colaboradores.
          </h4>
          <ul className="b2b-hero__description description">
            <li className="description__item">
              <Icon iconId="check-icon" />
              Planos inovadores e flexíveis
            </li>
            <li className="description__item">
              <Icon iconId="check-icon" />
              100% digital e eficiente para o RH
            </li>
            <li className="description__item">
              <Icon iconId="check-icon" />
              Saúde financeira para o colaborador
            </li>
          </ul>
          <Button onClick={onClickSidebar} text="Comece Agora" />
        </div>
        <img
          className="b2b-hero__ilustration"
          src="/images/hero-b2b.png"
          alt="ilustração com logo da Onze"
        />
      </div>
    </section>
  )
}

export default enhancer(SectionHero)
