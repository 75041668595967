import Header from 'components/Header'
import Sidebar from 'components/Sidebar'
import Menu from 'components/Menu'
import Footer from 'components/Footer'

import SectionForm from 'components/SectionForm'
import SectionAppsInstitutional from 'components/SectionAppsInstitutional'
import SectionPrev from 'components/SectionPrev'
import SectionPartners from 'components/SectionPartners'
import SectionPrevTech from 'components/SectionPrevTech'

import SectionHero from './components/SectionHero'

const Home = () => {
  return (
    <div>
      <Header />
      <Menu />
      <Sidebar>
        <SectionForm customClass="sidebar" />
      </Sidebar>
      <SectionHero twoButtons={true} />
      <SectionPrev />
      <SectionPartners />
      <SectionPrevTech />
      <SectionAppsInstitutional />
      <Footer />
    </div>
  )
}

export default Home
