import Staw from 'staw'

import Button from 'components/Button'
import Condition from 'components/Condition'
import useBreakpoint from 'hooks/useBreakpoint'
import enhancer from './hooks'
import Icon from 'rv-components-onze/src/Icon'

const companiesBenefits = [
  {
    image: 'icon-chart',
    title: 'Alto valor para o colaborador'
  },
  {
    image: 'icon-talent',
    title: 'Retenção dos melhores talentos'
  },
  {
    image: 'icon-flower',
    title: 'Baixo custo e incentivo fiscal'
  },
]

const employeesBenefits = [
  {
    image: 'icon-wallet',
    title: 'Gestão especializada do seu dinheiro'
  },
  {
    image: 'icon-pig',
    title: 'Diagnóstico da sua Saúde Financeira'
  },
  {
    image: 'icon-atendimento',
    title: 'Atendimento financeiro personalizado'
  },
]

const Container = ({ children, isMobile, onClick, buttonStyle }) => {
  return (
    <div className="section-both-prev__benefits benefits">
      <Condition
        condition={isMobile}
        elseCondition={children}
      >
        <Staw hasArrows={false} hasDots={true}>
          {children}
        </Staw>
      </Condition>

      <div className="button-wrapper">
        <Button
          customClass="benefits__button"
          text="Saiba mais"
          style={buttonStyle}
          onClick={onClick}
        />
      </div>
    </div>
  )
}

const SectionPrev = ({ onClickSidebar }) => {
  const { isMobile } = useBreakpoint()
  return (
    <section className="section-both-prev">
      <div className="section-both-prev__background" />
      <div className="container section-both-prev__content">
        <h2 className="section-both-prev__title">
          Você nunca prestou atenção na Previdência. Até agora.
        </h2>
        <p className="section-both-prev__description">
          Oferecemos uma solução completa, com taxas justas e benefícios únicos:
        </p>

        <div className="section-both-prev__benefits-container">
          <Container isMobile={isMobile} onClick={onClickSidebar} buttonStyle="orange">
            {companiesBenefits.map((item, index) => {
              return (
                <div className="benefits__item" key={index}>
                  <div className="benefits__image">
                    <Icon iconId={item.image} className="benefits__icon" />
                  </div>
                  <h3 className="benefits__title">{item.title}</h3>
                </div>
              )
            })}
          </Container>

          <Container isMobile={isMobile} onClick={onClickSidebar} buttonStyle="primary">
            {employeesBenefits.map((item, index) => {
              return (
                <div className="benefits__item" key={index}>
                  <div className="benefits__image">
                    <Icon iconId={item.image} className="benefits__icon" />
                  </div>
                  <h3 className="benefits__title">{item.title}</h3>
                </div>
              )
            })}
          </Container>
        </div>
      </div>
    </section>
  )
}

export default enhancer(SectionPrev)
